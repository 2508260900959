import React, { Component } from "react";
import {
  Form,
  Segment,
  Radio,
  Grid,
  Container,
  Button,
  Loader,
  Dimmer,
  Modal,
} from "semantic-ui-react";

import Results from "./Results.js";

import axios from "axios";
import { scoreAxios, similarityAxios } from "../App.js";

var parser = require("tld-extract");

class Search extends Component {
  state = {
    type: "query",
    url: "",
    prevUrl: "",
    text: "",
    trustScore: 0,
    similarArticles: [],
    mode: "url",
    loading: false,
    errors: "",
    reported: 0,
    reportSubmitted: false,
    sameUrlSubmitted: false,
    open: false,
  };

  handleChange = (e, { name, value }) => this.setState({ [name]: value });

  handleUrlModeClick = () => {
    this.setState({ mode: "url" });
    this.setState({ trustScore: 0 });
  };

  handleTextModeClick = () => {
    this.setState({ mode: "text" });
    this.setState({ trustScore: 0 });
  };

  handleSubmit = () => {
    const { text, url, prevUrl, mode } = this.state;
    this.setState({ loading: true });
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (mode === "text") {
      this.setState({ errors: "" });
      this.setState({ reported: 0 });
      this.setState({ sameUrlSubmitted: false });
      scoreAxios
        .post("/api/text", { type: "text_query", content: { text: text } })
        .then((res) => {
          this.setState({ loading: false });
          this.setState({ trustScore: res.data.content.score });
          const recommendations = [];
          for (let i = 0; i < res.data.content.recommendations.length; i++) {
            recommendations.push([
              res.data.content.recommendations[i].score,
              res.data.content.recommendations[i].url,
              res.data.content.recommendations[i].header,
            ]);
          }
          this.setState({ similarArticles: recommendations });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      if (url === prevUrl) {
        this.setState({ sameUrlSubmitted: true });
      } else {
        this.setState({ sameUrlSubmitted: false });
      }

      this.setState({ errors: "" });
      this.setState({ url: url.trim() });
      scoreAxios
        .post("/api/url", { type: "url_query", content: { url: url } })
        .then((res) => {
          if (res.data.type === "error") {
            this.setState({ loading: false });
            this.setState({
              errors:
                'This doesn\'t look like a valid URL. Make sure to use a full URL, including "http://" or "https://". Please check again and re-submit.',
            });
          } else {
            this.setState({ prevUrl: this.state.url });
            this.setState({ loading: false });
            this.setState({ trustScore: res.data.content.score });
            const recommendations = [];
            for (let i = 0; i < res.data.content.recommendations.length; i++) {
              recommendations.push([
                res.data.content.recommendations[i].score,
                res.data.content.recommendations[i].url,
                res.data.content.recommendations[i].header,
              ]);
            }
            this.setState({ similarArticles: recommendations });
          }
        })
        .then((res) => {
          similarityAxios.post("check/pinocchio", { url: url }).then((res) => {
            this.setState({ reported: res.data.counter });
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  render() {
    const {
      url,
      text,
      trustScore,
      similarArticles,
      mode,
      errors,
      reported,
      reportSubmitted,
      sameUrlSubmitted,
      open,
    } = this.state;

    return (
      <div>
        <Grid centered columns={3}>
          <Grid.Row style={{ paddingTop: "0em", marginBottom: "0em" }}>
            <h3>
              Check how trustworthy a 🇬🇧 news article is and receive trusted
              recommendations around its content
            </h3>
          </Grid.Row>
          <Grid.Row style={{ paddingTop: "0em", marginBottom: "0em" }}>
            <Modal
              onClose={() => this.setState({ open: false })}
              onOpen={() => this.setState({ open: true })}
              open={open}
              trigger={
                <h5>
                  Copy and paste an article URL or some of its text to calculate
                  the trustworthiness score. <a>What is the Pinocchio score?</a>
                </h5>
              }
            >
              <Modal.Header>About the Pinocchio score</Modal.Header>
              <Modal.Content>
                <p>
                  The Pinocchio trustworthiness score is based on a custom
                  Machine Learning model which has been trained on thousands of
                  past UK articles to recognise articles that may be fake news.
                </p>
                <ul>
                  <li>
                    A score of below 50% is likely to be{" "}
                    <span style={{ color: "green" }}>trustworthy</span>.
                  </li>
                  <li>
                    A score of over 50% is likely to be{" "}
                    <span style={{ color: "red" }}>fake news</span>.
                  </li>
                  <li>A score of around 50% indicates uncertainty.</li>
                </ul>
                <p>
                  Remember to always do additional research via the recommended
                  articles, and report the score if it doesn't seem right!
                </p>
              </Modal.Content>
            </Modal>
          </Grid.Row>
          <Grid.Row>
            <Button.Group>
              <Button
                positive={this.state.mode === "url"}
                onClick={this.handleUrlModeClick}
              >
                URL Mode
              </Button>
              <Button.Or text="or" />
              <Button
                positive={this.state.mode === "text"}
                onClick={this.handleTextModeClick}
              >
                Text Mode
              </Button>
            </Button.Group>
          </Grid.Row>
        </Grid>
        {this.state.loading ? (
          <Dimmer active page>
            <Loader size="massive">
              Scanning thousands of articles in our database. This may take a
              few seconds...
            </Loader>
          </Dimmer>
        ) : (
          <div></div>
        )}
        <br />
        <Form onSubmit={this.handleSubmit}>
          {this.state.mode === "text" ? (
            <Form.TextArea
              label="Search text"
              placeholder="Paste a suspicious sentence or a paragraph from an article."
              name="text"
              value={text}
              onChange={this.handleChange}
            />
          ) : (
            <Form.Input
              fluid
              label="Source URL"
              placeholder="https://fakenews.com/article-lol"
              name="url"
              value={url}
              onChange={this.handleChange}
            />
          )}
          <Form.Button>Submit</Form.Button>
        </Form>
        {this.state.loading ? null : trustScore > 0 ? (
          <Results
            similarArticles={similarArticles}
            url={url}
            text={text}
            trustScore={trustScore}
            mode={mode}
            reported={reported}
            reportSubmitted={false}
            sameUrlSubmitted={this.state.sameUrlSubmitted}
          />
        ) : null}
        {errors.length > 0 ? (
          <Segment color="red">
            <p>{errors}</p>
          </Segment>
        ) : null}
      </div>
    );
  }
}

export default Search;
