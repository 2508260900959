import React, { Link, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Menu,
  Container,
  Image,
  Button,
  Modal,
  Icon,
  Form,
} from "semantic-ui-react";

import logo from "../assets/logo-white.png";

import axios from "axios";

const Navbar = () => {
  return (
    <Menu fixed="top" inverted borderless>
      <Container>
        <Menu.Item as="a" header disabled>
          <Image size="tiny" src={logo} style={{ marginRight: "1.5em" }} />
        </Menu.Item>
        <Menu.Item
          href="https://github.com/TTDS-Pinocchio/"
          target="_blank"
          header
          color="white"
        >
          GitHub
        </Menu.Item>
      </Container>
    </Menu>
  );
};

export default Navbar;
