const mediaSources = {
	"bbc.co.uk": {
		bias: "LEFT-CENTER",
		facts: "HIGH",
		country: "United Kingdom",
		type: "TV Station",
		traffic: "High Traffic",
		credibility: "HIGH CREDIBILITY",
	},
	"dailyrecord.co.uk": {
		bias: "No data available",
		facts: "MIXED",
		type: "Newspaper",
		country: "United Kingdom (Scotland)",
		traffic: "No data available",
		credibility: "No data available",
	},
	"dailymail.co.uk": {
		bias: "RIGHT",
		facts: "LOW",
		country: "United Kingdom",
		type: "Newspaper",
		traffic: "High Traffic",
		credibility: "LOW CREDIBILITY",
	},
	"express.co.uk": {
		bias: "RIGHT",
		facts: "MIXED",
		country: "United Kingdom",
		type: "Newspaper",
		traffic: "High Traffic",
		credibility: "MEDIUM CREDIBILITY",
	},
	"dailyexpress.co.uk": {
		bias: "RIGHT",
		facts: "MIXED",
		country: "United Kingdom",
		type: "Newspaper",
		traffic: "High Traffic",
		credibility: "MEDIUM CREDIBILITY",
	},
	"independent.co.uk": {
		bias: "LEFT-CENTER",
		facts: "MIXED",
		country: "United Kingdom",
		type: "Newspaper",
		traffic: "High Traffic",
		credibility: "MEDIUM CREDIBILITY",
	},
	"manchestereveningnews.co.uk": {
		bias: "No data available",
		facts: "No data available",
		country: "United Kingdom",
		type: "Newspaper",
		traffic: "No data available",
		credibility: "No data available",
	},
	"mirror.co.uk": {
		bias: "LEFT-CENTER",
		facts: "MIXED",
		type: "Newspaper",
		country: "United Kingdom",
		traffic: "No data available",
		credibility: "No data available",
	},
	"glasgowtimes.co.uk": {
		bias: "No data available",
		facts: "No data available",
		country: "United Kingdom",
		type: "Newspaper",
		traffic: "No data available",
		credibility: "No data available",
	},
	"standard.co.uk": {
		bias: "RIGHT-CENTER",
		facts: "HIGH",
		country: "United Kingdom (England/London)",
		type: "Newspaper",
		traffic: "No data available",
		credibility: "No data available",
	},
	"telegraph.co.uk": {
		bias: "RIGHT",
		facts: "MIXED",
		country: "United Kingdom",
		type: "Newspaper",
		traffic: "No data available",
		credibility: "No data available",
	},
};

export { mediaSources };
