import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import "./App.css";
import { Container } from "semantic-ui-react";
import Navbar from "./components/Navbar";
import Main from "./components/Main";

import axios from "axios";

// IMPORTANT: API BASEURL NEEDS TO GO HERE
axios.defaults.baseURL = "https://geppetto.xyz:8000";

const similarityAxios = axios.create({
	baseURL: "https://europe-west2-college-phish-api.cloudfunctions.net/api/",
});

const scoreAxios = axios.create({
	baseURL: "https://geppetto.xyz:8000",
});

function App() {
	return (
		<Container>
			<Router>
				<Navbar />
				<Route exact path="/" component={Main} />
			</Router>
		</Container>
	);
}

export { scoreAxios, similarityAxios };

export default App;
