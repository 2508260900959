import React, { Component } from "react";
import {
	Segment,
	Container,
	Modal,
	Button,
	Image,
	Header,
	Label,
	Card,
	Popup,
	Icon,
	Message,
	Grid,
} from "semantic-ui-react";

import logo from "../assets/logo.png";
import axios from "axios";

import { mediaSources } from "../util/mediaSources.js";

import { similarityAxios } from "../App.js";

var parser = require("tld-extract");

class Results extends Component {
	constructor(props) {
		super(props);
		this.state = {
			similarArticles: this.props.similarArticles,
			trustScore: this.props.trustScore,
			mode: this.props.mode,
			errors: this.props.errors,
			reported: this.props.reported,
			url: this.props.url,
			reportSubmitted: false,
			sameUrlSubmitted: this.props.sameUrlSubmitted,
			open: false,
		};
	}

	componentDidUpdate(prevProps) {
		// Typical usage (don't forget to compare props):
		if (this.props.similarArticles !== prevProps.similarArticles) {
			this.setState({ similarArticles: this.props.similarArticles });
		}
		if (this.props.trustScore !== prevProps.trustScore) {
			this.setState({ trustScore: this.props.trustScore });
		}
		if (this.props.mode !== prevProps.mode) {
			this.setState({ mode: this.props.mode });
		}
		if (this.props.errors !== prevProps.errors) {
			this.setState({ errors: this.props.errors });
		}

		if (this.props.sameUrlSubmitted !== prevProps.sameUrlSubmitted) {
			this.setState({ sameUrlSubmitted: this.props.sameUrlSubmitted });
		}

		if (this.props.reported !== prevProps.reported) {
			this.setState({ reported: this.props.reported });
		}
	}

	handleClick = (e) => {
		e.preventDefault();
		similarityAxios
			.post("submit/pinocchio", { url: this.state.url })
			.then((res) => {
				this.setState({ reportSubmitted: true });
			});
	};

	render() {
		let scoreMarkup = null;
		let similarArticleMarkup = [];
		let reportedMarkup = null;

		if (this.state.reported > 0) {
			reportedMarkup = (
				<h3>
					However, {this.state.reported} other user(s) flagged that
					our score may not be accurate!{" "}
					{this.state.sameUrlSubmitted ? null : !this.state
							.reportSubmitted ? (
						<span>
							<a href="#" onClick={this.handleClick}>
								{" "}
								Click here
							</a>{" "}
							if you also believe that the score is wrong.
						</span>
					) : null}
				</h3>
			);
		}

		if (this.state.trustScore < 0.5) {
			scoreMarkup = (
				<div>
					<h4>
						Pinocchio thinks that this information looks a lot like{" "}
						<span style={{ color: "red" }}>fake news</span>, with a
						trust score of{" "}
						<span style={{ color: "red" }}>
							{parseFloat(this.state.trustScore * 100).toFixed(2)}
							%
						</span>
						.
					</h4>
				</div>
			);
		} else {
			scoreMarkup = (
				<div>
					<h4>
						Pinocchio thinks that the information is{" "}
						<span style={{ color: "green" }}>trustworthy</span>,
						with a trust score of{" "}
						<span style={{ color: "green" }}>
							{parseFloat(this.state.trustScore * 100).toFixed(2)}
							%
						</span>
						.
					</h4>
				</div>
			);
		}

		if (this.state.similarArticles.length === 0) {
			similarArticleMarkup = <p>No similar articles to display.</p>;
		} else {
			this.state.similarArticles.forEach((article) => {
				similarArticleMarkup.push(
					<Card fluid>
						<Image
							wrapped
							ui={false}
							src={`//logo.clearbit.com/${
								parser(article[1])["domain"]
							}`}
						/>
						<Card.Content>
							<Card.Header>
								<a href={article[1]}>{article[2]}</a>
							</Card.Header>
							<br />
							<Card.Meta>
								{`— from ${parser(article[1])["domain"]}`}
								<Popup
									trigger={
										<p>
											<a>About this source</a>
										</p>
									}
									inverted
									hoverable
								>
									<h4>
										<a
											href={`https://mediabiasfactcheck.com/?s=${
												parser(article[1])["domain"]
											}`}
											target="_blank"
										>
											View full report on this source{" "}
										</a>
									</h4>
									<p>
										Bias:{" "}
										{
											mediaSources[
												parser(article[1])["domain"]
											].bias
										}
									</p>
									<p>
										Factual reporting:{" "}
										{
											mediaSources[
												parser(article[1])["domain"]
											].facts
										}
									</p>
									<p>
										Country:{" "}
										{
											mediaSources[
												parser(article[1])["domain"]
											].country
										}
									</p>
									<p>
										Media Type:{" "}
										{
											mediaSources[
												parser(article[1])["domain"]
											].type
										}
									</p>
									<p>
										Popularity:{" "}
										{
											mediaSources[
												parser(article[1])["domain"]
											].traffic
										}
									</p>
									<p>
										Credibility:{" "}
										{
											mediaSources[
												parser(article[1])["domain"]
											].credibility
										}
									</p>
								</Popup>
							</Card.Meta>
							<h5>
								Pinocchio score:{" "}
								<strong style={{ color: "blue" }}>
									{article[0]}%
								</strong>
							</h5>
							<a href={article[1]}>
								<Button>View article</Button>
							</a>
						</Card.Content>
					</Card>
				);
			});
		}

		return (
			<div>
				<br />

				{this.state.sameUrlSubmitted ? (
					<Message
						negative
						content="Looks like you just searched for this article"
					/>
				) : null}
				<Segment color="blue" small>
					{scoreMarkup}
					{reportedMarkup}
					<br />
					{this.state.mode === "url" &&
					this.state.reported === 0 &&
					!this.state.reportSubmitted &&
					!this.state.sameUrlSubmitted ? (
						<p>
							Does this score look suspicious? Click{" "}
							<a href="#" onClick={this.handleClick}>
								here
							</a>{" "}
							to report.
						</p>
					) : this.state.mode === "url" &&
					  this.state.reportSubmitted &&
					  !this.sameUrlSubmitted ? (
						<Message
							success
							header="Thanks!"
							content="Your report has been submitted. The next time someone searches for the same article, they will be notified about how many people flagged its score as potentially inaccurate."
						/>
					) : null}
				</Segment>
				<Header>Related Articles</Header>
				<Modal
					onClose={() => this.setState({ open: false })}
					onOpen={() => this.setState({ open: true })}
					open={this.state.open}
					trigger={
						<h5>
							<a>About related articles</a>
						</h5>
					}
				>
					<Modal.Header>Article recommendations</Modal.Header>
					<Modal.Content>
						<p>
							The recommendations are based on the content of your
							submitted article URL or a phrase you've entered.
						</p>
						<p>
							Pinocchio scans its periodically updated news
							article database to look for articles that are
							similar to your submission and have a high Pinocchio
							trustworthiness score.
						</p>
						<p>
							Although the database contains articles from
							registered news outlets, you should click the "About
							this source" to check for any potential biases in a
							given recommendation and ultimately make your own
							judgement.
						</p>
					</Modal.Content>
				</Modal>
				<br />
				<Card.Group itemsPerRow={5} stackable={true}>
					{similarArticleMarkup}
				</Card.Group>
				<br />
			</div>
		);
	}
}

export default Results;
